import { closePopup, OverlayHoverMessage, PopupButtonType, PopupIconType, PopupRef, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import { PlayerOvenMedia } from "@kalyzee/kast-react-player-module";
import React, { createRef, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import { ReactComponent as IconData } from "../../../assets/icons/data.svg";
import { ReactComponent as IconError } from "../../../assets/icons/error.svg";
import { ReactComponent as IconId } from "../../../assets/icons/id.svg";
import { ReactComponent as IconLink } from "../../../assets/icons/link.svg";
import { ReactComponent as IconPlay } from "../../../assets/icons/play.svg";
import { ReactComponent as IconPlayer } from "../../../assets/icons/player.svg";
import { ReactComponent as IconStats } from "../../../assets/icons/stats.svg";
import { ReactComponent as IconTracks } from "../../../assets/icons/tracks.svg";
import { ReactComponent as IconThumbnail } from "../../../assets/icons/thumbnail.svg";
import TableAudioTrack from "../../../components/tables/table-audio-track";
import TableRecord from "../../../components/tables/table-record";
import TableRedirection from "../../../components/tables/table-redirection";
import TableVideoTrack from "../../../components/tables/table-video-track";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from "../../../components/utils/Table";
import Colors from "../../../constants/colors";
import { getBaseUrl, getConfQueries, getOvenMediaHlsUrl, getOvenMediaLLHlsUrl, getOvenMediaOvtUrl, getOvenMediaWebsocketUrl } from "../../../helpers/request";
import { toastError, toastSuccess } from "../../../helpers/toast";
import { addToPathNameUrl } from "../../../helpers/utils";
import { useSocketAppDispatch } from "../../../hooks/app";
import { useRender } from "../../../hooks/component";
import { useElementSize } from "../../../hooks/window";
import {
  OvenMediaAppContext,
  OvenMediaAppOutputProfile,
  OvenMediaAppOutputProfilePlaylist,
  OvenMediaAppPublisher,
  OvenMediaContext,
  OvenMediaLiveSession,
  OvenMediaRecordSession,
  OvenMediaRecordSessionStatus,
  OvenMediaRedirectionSession,
  OvenMediaRedirectionSessionStatus,
  OvenMediaStreamContext,
  OvenMediaTrackAudio,
  OvenMediaTrackVideo,
} from "../../../interfaces/context";
import { OvenMediaLocalStats, OvenMediaStreamStats } from "../../../interfaces/stats";
import { socketAddRedirection, socketDeleteRedirection, socketStartRecord, socketStopRecord, socketStopStream } from "../../../store/socket/actions";
import PopupStatsNetworkStats from "./popup-stats-network";
import styles from "./table-application.module.css";

export interface TableApplicationData {
  context: OvenMediaContext;
  app: OvenMediaAppContext;
  stream: OvenMediaStreamContext;
  stats?: OvenMediaStreamStats;
  ovts?: { context: OvenMediaContext; app: OvenMediaAppContext; stream: OvenMediaStreamContext; stats?: OvenMediaStreamStats }[];
}

export interface TableApplicationRef {
  render: () => void;
}

export interface TableApplicationProps {
  showApp: boolean;
  showServer: boolean;
  hideHeader?: boolean;
  data: TableApplicationData[];
  onItemChecked?: (item: TableApplicationData) => void;
  onConf?: (conf: TableConf<TableApplicationData>) => TableConf<TableApplicationData>;
  className?: string;
  style?: React.CSSProperties;
}
const TableApplication = React.forwardRef(
  (
    { showApp, showServer, hideHeader, data, onItemChecked, onConf, className, style }: TableApplicationProps,
    forwardRef: React.ForwardedRef<TableApplicationRef | undefined>
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const size = useElementSize(containerRef);
    const socketDispatch = useSocketAppDispatch();
    const [itemsActive, setItemActive] = useState<{ server: string; app: string; stream: string }[]>([]);

    const getViewers = (d: TableApplicationData, field?: keyof OvenMediaLocalStats["connections"], withOvts = true) => {
      let viewers = 0;
      const connections = d.stats?.stats?.connections;
      if (connections) {
        if (field) {
          viewers += connections[field] ?? 0;
        } else {
          for (let connection of Object.values(connections)) {
            viewers += connection ?? 0;
          }
        }
      }
      if (withOvts && d.ovts) {
        d.ovts.forEach((o) => {
          const connections = o.stats?.stats?.connections;
          if (connections) {
            if (field) {
              viewers += connections[field] ?? 0;
            } else {
              for (let connection of Object.values(connections)) {
                viewers += connection ?? 0;
              }
            }
          }
        });
      }
      return viewers;
    };

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableApplicationData>[] = [
        /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
        {
          type: TableColumnType.CLASSIC,
          key: "id",
          width: "35px",
          title: "Id" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        showServer
          ? {
              type: TableColumnType.CLASSIC,
              key: "server",
              width: "50px",
              title: "Server" /* TRANSLATION */,
              ascendantSort: (a: TableApplicationData, b: TableApplicationData) => a.stream.live?._server.localeCompare(b.stream.live?._server ?? ""),
              descendantSort: (a: TableApplicationData, b: TableApplicationData) => b.stream.live?._server.localeCompare(a.stream.live?._server ?? ""),
            }
          : undefined,
        {
          type: TableColumnType.CLASSIC,
          key: "name",
          width: "11rem",
          title: "Name" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "key",
          minWidth: "7rem",
          title: "Stream (key)" /* TRANSLATION */,
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => a.stream.stream.localeCompare(b.stream.stream),
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => b.stream.stream.localeCompare(a.stream.stream),
        },
        showApp
          ? {
              type: TableColumnType.CLASSIC,
              key: "app",
              width: "10rem",
              title: "App" /* TRANSLATION */,
              ascendantSort: (a: TableApplicationData, b: TableApplicationData) => a.stream.app.localeCompare(b.stream.app),
              descendantSort: (a: TableApplicationData, b: TableApplicationData) => b.stream.app.localeCompare(a.stream.app),
            }
          : undefined,
        {
          type: TableColumnType.CLASSIC,
          key: "startedAt",
          title: "Date" /* TRANSLATION */,
          width: "10rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => {
            const dateA = a.stream?.live?.startedAt ?? a.stream.input?.createdTime ?? 0;
            const dateB = b.stream?.live?.startedAt ?? b.stream.input?.createdTime ?? 0;
            return new Date(dateB).getTime() - new Date(dateA).getTime();
          },
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => {
            const dateA = a.stream?.live?.startedAt ?? a.stream.input?.createdTime ?? 0;
            const dateB = b.stream?.live?.startedAt ?? b.stream.input?.createdTime ?? 0;
            return new Date(dateA).getTime() - new Date(dateB).getTime();
          },
          defaultSort: TableSortDirection.DESC,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "record",
          title: "Record" /* TRANSLATION */,
          width: "5rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => (a.stream.records?.length ?? 0) - (b.stream.records?.length ?? 0),
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => (b.stream.records?.length ?? 0) - (a.stream.records?.length ?? 0),
        },
        {
          type: TableColumnType.CLASSIC,
          key: "redirections",
          title: "Redirections" /* TRANSLATION */,
          width: "6rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => (a.stream.redirections?.length ?? 0) - (b.stream.redirections?.length ?? 0),
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => (b.stream.redirections?.length ?? 0) - (a.stream.redirections?.length ?? 0),
        },
        {
          type: TableColumnType.CLASSIC,
          key: "client",
          title: "Client" /* TRANSLATION */,
          width: "7rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => a.stream.live?.clientIp?.localeCompare(b.stream.live?.clientIp ?? "") ?? 0,
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => b.stream.live?.clientIp?.localeCompare(a.stream.live?.clientIp ?? "") ?? 0,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "source",
          title: "Source" /* TRANSLATION */,
          width: "8rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => a.stream.input?.sourceType?.localeCompare(b.stream.input?.sourceType ?? "") ?? 0,
          descendantSort: (a: TableApplicationData, b: TableApplicationData) =>
            b.stream.input?.sourceType?.localeCompare(a.stream.input?.sourceType ?? "") ?? 0,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "viewers",
          title: "Viewers" /* TRANSLATION */,
          width: "6rem",
          ascendantSort: (a: TableApplicationData, b: TableApplicationData) => getViewers(a) - getViewers(b),
          descendantSort: (a: TableApplicationData, b: TableApplicationData) => getViewers(b) - getViewers(a),
        },
        {
          type: TableColumnType.CLASSIC,
          key: "stats",
          title: "Stats" /* TRANSLATION */,
          width: "4rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "tracks",
          title: "Tracks" /* TRANSLATION */,
          width: "4rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "data",
          title: "Data" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "actions",
          title: "Actions" /* TRANSLATION */,
          width: "10rem",
          enableSort: false,
        },
      ].filter((curr) => curr) as TableConfColumn<TableApplicationData>[];

      const tableConfiguration: TableConf<TableApplicationData> = {
        columns: columnConfiguration,
        header: {
          hide: hideHeader,
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return onConf ? onConf(tableConfiguration) : tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableApplicationData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableApplicationData) => {
      if (columnKey === "id") {
        const ref = createRef<HTMLDivElement>();
        const live = item.stream.live;
        if (!live) {
          return (
            <div className={styles.name}>
              <IconError width={17} height={17} />
            </div>
          );
        }
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Id:" /* TRANSLATION */}</div>
                      <div>{live.id}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <IconId width={17} height={17} />
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={live.id} />
          </div>
        );
      }
      if (columnKey === "name") {
        const ref = createRef<HTMLDivElement>();
        const name = item.stream.live?.name ?? "";
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Name:" /* TRANSLATION */}</div>
                      <div>{name}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {name}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={name} />
          </div>
        );
      }
      if (columnKey === "server") {
        const ref = createRef<HTMLDivElement>();
        const server = item.stream.live?._server ?? item.context.server._id;
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Server:" /* TRANSLATION */}</div>
                      <div>{server}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {server}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={server} />
          </div>
        );
      }
      if (columnKey === "key") {
        const ref = createRef<HTMLDivElement>();
        const key = item.stream.stream;
        return (
          <div ref={ref} className={styles.key}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Key:" /* TRANSLATION */}</div>
                      <div>{key}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {key}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={key} />
          </div>
        );
      }
      if (columnKey === "app") {
        return (
          <Touchable
            className={[styles.app, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10 }}>{"App:" /* TRANSLATION */}</div>
                    <div>{item.stream.app}</div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.stream.app}
          </Touchable>
        );
      }
      if (columnKey === "record") {
        const records: OvenMediaRecordSession[] = item.stream.records ?? [];
        const atLeastOneSessionIsRunning = records.find((r) => r.status === OvenMediaRecordSessionStatus.RECORDING);
        return (
          <Touchable
            className={records?.length ? styles.recordYes : styles.recordFalse}
            onPress={() => {
              const live = item.stream.live;
              let popupIndex: number;
              const addRecord = (live: OvenMediaLiveSession) => {
                const trackVideos: string[] = [];
                const trackAudios: string[] = [];
                item.stream?.outputs?.forEach((o) => {
                  o?.tracks?.forEach((t) => {
                    if (t.type === "Video" && !trackVideos.includes(t.name)) trackVideos.push(t.name);
                    if (t.type === "Audio" && !trackAudios.includes(t.name)) trackAudios.push(t.name);
                  });
                });
                let trackVideosButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
                let trackAudiosButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
                let trackVideo = trackVideos[0];
                let trackAudio = trackAudios[0];
                showPopup(
                  {
                    title: "Lancer un nouvel enregistrement ?",
                    iconTitle: PopupIconType.INFO,
                    content: (
                      <div>
                        <div className={styles.popupTitle}>Choisissez les tracks que vous voulez utiliser.</div>
                        <div className={styles.popupTrackSelectionContainer}>
                          <div className={styles.popupSubtitle}>Videos:</div>
                          <div className={styles.popupTrackSelectionList}>
                            {trackVideos.map((t, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupTrackSelectionButton];
                              if (t === trackVideo) currClasses.push(styles.popupTrackSelectionButtonSelected);
                              trackVideosButtonContainerRef[i] = ref;
                              return (
                                <div key={t} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      trackVideo = t;
                                      trackVideosButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupTrackSelectionButtonSelected);
                                        else classList?.remove(styles.popupTrackSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {t}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className={styles.popupTrackSelectionContainer}>
                          <div className={styles.popupSubtitle}>Audios:</div>
                          <div className={styles.popupTrackSelectionList}>
                            {trackAudios.map((t, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupTrackSelectionButton];
                              if (t === trackAudio) currClasses.push(styles.popupTrackSelectionButtonSelected);
                              trackAudiosButtonContainerRef[i] = ref;
                              return (
                                <div key={t} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      trackAudio = t;
                                      trackAudiosButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupTrackSelectionButtonSelected);
                                        else classList?.remove(styles.popupTrackSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {t}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ),
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Annuler" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Valider" /* TRANSLATION */,
                        onClick: async () => {
                          const tracks = [trackVideo, trackAudio];
                          const result = await socketDispatch(socketStartRecord({ serverId: live._server, data: { liveSession: live.id, tracks } }));

                          if (result.error) {
                            toastError(`Impossible de créer une nouveau record. Error : ${result.error}`);
                          } else {
                            toastSuccess(`Votre flux est maintenant en train d'être enregistré`);
                            closePopup(popupIndex);
                          }
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: false,
                    enableCloseButton: false,
                  },
                  undefined,
                  { displayHover: true }
                );
              };
              popupIndex = showPopup({
                style: { width: "90%" },
                content: (
                  <div className={styles.popupRecordContainer}>
                    <div className={styles.popupTitle}>{"Records:" /* TRANSLATION */}</div>
                    <div>
                      {records.length ? (
                        <TableRecord
                          data={records.map((record, index) => ({
                            stream: item.stream,
                            session: record,
                          }))}
                          onStopRecord={async (item) => {
                            const record = item.session;
                            if (!record) return;
                            const result = await socketDispatch(socketStopRecord({ serverId: record._server, data: { recordSession: record.id } }));
                            if (!result.error) {
                              toastSuccess("La session a bien été stoppée.");
                            } else {
                              toastError(`Impossible d'arrêter la session ${record.id}`);
                            }
                            closePopup(popupIndex);
                          }}
                        />
                      ) : (
                        <div>Aucun enregistrement en cours... </div>
                      )}
                    </div>
                    {live ? (
                      <Touchable
                        className={styles.popupAddRecordButton}
                        onPress={async () => {
                          addRecord(live);
                        }}
                      >
                        <div style={{ width: "10px", height: "10px", borderRadius: "100%", backgroundColor: Colors.getTorchRed() }} />
                        Démarrer un nouvel enregistrement
                      </Touchable>
                    ) : null}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {atLeastOneSessionIsRunning ? <div style={{ width: "10px", height: "10px", borderRadius: "100%", backgroundColor: Colors.getTorchRed() }} /> : null}
            {records.length ?? 0}
          </Touchable>
        );
      }
      if (columnKey === "redirections") {
        const redirections: OvenMediaRedirectionSession[] = item.stream.redirections ?? [];
        const atLeastOneSessionIsRunning = redirections.find((r) => r.status === OvenMediaRedirectionSessionStatus.RUNNING);
        return (
          <Touchable
            className={redirections?.length ? styles.redirectionYes : styles.redirectionFalse}
            onPress={() => {
              const live = item.stream.live;
              const addRedirection = (live: OvenMediaLiveSession) => {
                const inputUrlRef = createRef<HTMLInputElement>();
                const inputKeyRef = createRef<HTMLInputElement>();
                const trackVideos: string[] = [];
                const trackAudios: string[] = [];
                item.stream?.outputs?.forEach((o) => {
                  o?.tracks?.forEach((t) => {
                    if (t.type === "Video" && !trackVideos.includes(t.name)) trackVideos.push(t.name);
                    if (t.type === "Audio" && !trackAudios.includes(t.name)) trackAudios.push(t.name);
                  });
                });
                let trackVideosButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
                let trackAudiosButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
                let trackVideo = trackVideos[0];
                let trackAudio = trackAudios[0];
                showPopup(
                  {
                    title: "Rediriger votre flux ?",
                    iconTitle: PopupIconType.INFO,
                    content: (
                      <div>
                        <div className={styles.popupTitle}>Renseigner l'url RTMP où sera retransmis votre flux.</div>
                        <div className={styles.popupSubtitle}>Url*:</div>
                        <input className={styles.popupInput} ref={inputUrlRef} />
                        <div className={styles.popupSubtitle}>Key:</div>
                        <input className={styles.popupInput} ref={inputKeyRef} />

                        <div style={{ marginBottom: "5px" }} />

                        <div className={styles.popupTitle}>Choisissez les tracks que vous voulez utiliser.</div>
                        <div className={styles.popupTrackSelectionContainer}>
                          <div className={styles.popupSubtitle}>Videos:</div>
                          <div className={styles.popupTrackSelectionList}>
                            {trackVideos.map((t, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupTrackSelectionButton];
                              if (t === trackVideo) currClasses.push(styles.popupTrackSelectionButtonSelected);
                              trackVideosButtonContainerRef[i] = ref;
                              return (
                                <div key={t} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      trackVideo = t;
                                      trackVideosButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupTrackSelectionButtonSelected);
                                        else classList?.remove(styles.popupTrackSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {t}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className={styles.popupTrackSelectionContainer}>
                          <div className={styles.popupSubtitle}>Audios:</div>
                          <div className={styles.popupTrackSelectionList}>
                            {trackAudios.map((t, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupTrackSelectionButton];
                              if (t === trackAudio) currClasses.push(styles.popupTrackSelectionButtonSelected);
                              trackAudiosButtonContainerRef[i] = ref;
                              return (
                                <div key={t} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      trackAudio = t;
                                      trackAudiosButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupTrackSelectionButtonSelected);
                                        else classList?.remove(styles.popupTrackSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {t}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ),
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Annuler" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Valider" /* TRANSLATION */,
                        onClick: async () => {
                          const url = inputUrlRef.current?.value?.trim();
                          const key = inputKeyRef.current?.value;
                          const tracks = [trackVideo, trackAudio];
                          let urlObj: URL | undefined;
                          try {
                            urlObj = new URL(url ?? "");
                          } catch (_) {}

                          if (!urlObj) {
                            toastError(`L'url saisie ne semble pas valide`);
                            return true;
                          } else if (urlObj.protocol !== "rtmp:" && urlObj.protocol !== "rtmps:") {
                            toastError(`L'url saisie n'est pas une url RTMP`);
                            return true;
                          }

                          const result = await socketDispatch(
                            socketAddRedirection({ serverId: live._server, data: { liveSession: live.id, url: urlObj.href, key, tracks } })
                          );
                          if (result.error) {
                            toastError(`Impossible de créer une nouvelle redirection. Error : ${result.error}`);
                          } else {
                            toastSuccess(`Votre flux est maintenant redirigé sur : ${urlObj.href}`);
                          }
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: false,
                    enableCloseButton: false,
                  },
                  undefined,
                  { displayHover: true }
                );
              };
              const popupIndex = showPopup({
                style: { width: "90%" },
                content: (
                  <div className={styles.popupRedirectionContainer}>
                    <div className={styles.popupTitle}>{"Redirections:" /* TRANSLATION */}</div>
                    <div>
                      {redirections.length ? (
                        <TableRedirection
                          data={redirections.map((record, index) => ({
                            context: item.stream,
                            session: record,
                          }))}
                          onStopRedirection={async (item) => {
                            const redirection = item.session;
                            if (!redirection) return;
                            const result = await socketDispatch(
                              socketDeleteRedirection({ serverId: redirection._server, data: { redirectionSession: redirection.id } })
                            );
                            if (!result.error) {
                              toastSuccess("La session a bien été stoppée.");
                            } else {
                              toastError(`Impossible d'arrêter la session ${redirection.id}`);
                            }
                            closePopup(popupIndex);
                          }}
                        />
                      ) : (
                        <div>Aucune redirection n'a été encore faite... </div>
                      )}
                    </div>
                    {live ? (
                      <Touchable
                        className={styles.popupAddRedirection}
                        onPress={async () => {
                          addRedirection(live);
                        }}
                      >
                        Ajouter une nouvelle redirection
                      </Touchable>
                    ) : null}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {atLeastOneSessionIsRunning ? <div style={{ width: "10px", height: "10px", borderRadius: "100%", backgroundColor: Colors.getTorchRed() }} /> : null}
            {redirections.length ?? 0}
          </Touchable>
        );
      }
      if (columnKey === "client") {
        return (
          <Touchable
            className={[styles.client, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Client:" /* TRANSLATION */}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• IP: " /* TRANSLATION */}</div>
                      <div className={[styles.textOverflow].join(" ")}>{`${item.stream.live?.clientIp?.toUpperCase()}`}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• PORT: " /* TRANSLATION */}</div>
                      <div className={[styles.textOverflow].join(" ")}>{`${item.stream.live?.clientPort}`}</div>
                    </div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.stream.live?.clientIp?.toUpperCase()}
          </Touchable>
        );
      }
      if (columnKey === "source") {
        const originSource = item.stream.live?.url;
        const newSource = item.stream.live?.newUrl;
        const inputSource = item.stream.input?.sourceUrl;
        return (
          <Touchable
            className={[styles.source, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Source:" /* TRANSLATION */}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• origin: " /* TRANSLATION */}</div>
                      <a className={[styles.recordLink].join(" ")} href={originSource} target="_blank">{`${originSource}`}</a>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• final: " /* TRANSLATION */}</div>
                      <a className={[styles.recordLink].join(" ")} href={newSource} target="_blank">{`${newSource}`}</a>
                    </div>
                    {inputSource ? (
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                        <div>{"• source: " /* TRANSLATION */}</div>
                        <a className={[styles.recordLink].join(" ")} href={inputSource} target="_blank">{`${inputSource}`}</a>
                      </div>
                    ) : null}
                    {item.ovts?.length ? (
                      <div>
                        <div style={{ margin: "10px 0px", fontWeight: "bold" }}>{"Ovt:" /* TRANSLATION */}</div>
                        {item.ovts.map((o) => {
                          const source = o.stream.input?.sourceUrl;
                          if (!source) return null;
                          return (
                            <div
                              key={`ovt_source_${item.stream}_${o.context.server._id}`}
                              style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}
                            >
                              <div>{"• source: " /* TRANSLATION */}</div>
                              <a className={[styles.recordLink].join(" ")} href={source} target="_blank">{`${source}`}</a>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.ovts?.length ? `${item.stream.input?.sourceType?.toUpperCase()}/OVT(s)` : item.stream.input?.sourceType?.toUpperCase()}
          </Touchable>
        );
      }
      if (columnKey === "viewers") {
        const viewers = getViewers(item);
        const totals = {
          dash: getViewers(item, "dash"),
          lldash: getViewers(item, "lldash"),
          hls: getViewers(item, "hls"),
          llhls: getViewers(item, "llhls"),
          ovt: getViewers(item, "ovt"),
          webrtc: getViewers(item, "webrtc"),
        };
        const currentServer = {
          dash: getViewers(item, "dash", false),
          lldash: getViewers(item, "lldash", false),
          hls: getViewers(item, "hls", false),
          llhls: getViewers(item, "llhls", false),
          ovt: getViewers(item, "ovt", false),
          webrtc: getViewers(item, "webrtc", false),
        };
        const getViewStyle = (v: number) => ({ fontSize: 18, fontWeight: "bold", color: v ? Colors.getMainGreen() : Colors.getMainBlack() });
        return (
          <Touchable
            className={styles.viewers}
            onPress={() => {
              showPopup({
                content: (
                  <div style={{ minWidth: 300 }}>
                    {item.ovts?.length ? (
                      <>
                        <div style={{ marginBottom: 10, fontWeight: "bold", fontSize: "1.3rem" }}>{`Total: ` /* TRANSLATION */}</div>
                        <div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>dash: </div>
                            <div style={getViewStyle(totals.dash)}>{totals.dash}</div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>lldash: </div>
                            <div style={getViewStyle(totals.lldash)}>{totals.lldash}</div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>hls: </div>
                            <div style={getViewStyle(totals.hls)}>{totals.hls}</div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>llhls: </div>
                            <div style={getViewStyle(totals.llhls)}>{totals.llhls}</div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>webrtc: </div>
                            <div style={getViewStyle(totals.webrtc)}>{totals.webrtc}</div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                            <div>ovt: </div>
                            <div style={getViewStyle(totals.ovt)}>{totals.ovt}</div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div style={{ marginBottom: 10, marginTop: 20, fontWeight: "bold", fontSize: "1.3rem" }}>{
                      `Current server ${item.context.server._id}` /* TRANSLATION */
                    }</div>
                    <div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>dash: </div>
                        <div style={getViewStyle(currentServer.dash)}>{currentServer.dash}</div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>lldash: </div>
                        <div style={getViewStyle(currentServer.lldash)}>{currentServer.lldash}</div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>hls: </div>
                        <div style={getViewStyle(currentServer.hls)}>{currentServer.hls}</div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>llhls: </div>
                        <div style={getViewStyle(currentServer.llhls)}>{currentServer.llhls}</div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>webrtc: </div>
                        <div style={getViewStyle(currentServer.webrtc)}>{currentServer.webrtc}</div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                        <div>ovt: </div>
                        <div style={getViewStyle(currentServer.ovt)}>{currentServer.ovt}</div>
                      </div>
                    </div>
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {viewers}
          </Touchable>
        );
      }
      if (columnKey === "stats") {
        return (
          <Touchable
            className={styles.stats}
            onPress={() => {
              const serverId = item.stream.live?._server ?? item.context.server._id;
              showPopup({
                content: (
                  <div style={{ minWidth: 300 }}>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Network:" /* TRANSLATION */}</div>
                    {item.stats?.network ? (
                      <div className={styles.statsNetworkContainer}>
                        <PopupStatsNetworkStats
                          mode="incoming"
                          serverId={serverId}
                          vhost={item.stream.vhost}
                          app={item.stream.app}
                          stream={item.stream.stream}
                        />
                        <PopupStatsNetworkStats
                          mode="outgoing"
                          serverId={serverId}
                          vhost={item.stream.vhost}
                          app={item.stream.app}
                          stream={item.stream.stream}
                        />
                      </div>
                    ) : null}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconStats width={20} height={20} />
          </Touchable>
        );
      }
      if (columnKey === "tracks") {
        const videoTracksInput: OvenMediaTrackVideo[] = item.stream.input?.tracks?.filter((track) => track.type === "Video") as OvenMediaTrackVideo[];
        const audioTracksInput: OvenMediaTrackAudio[] = item.stream.input?.tracks?.filter((track) => track.type === "Audio") as OvenMediaTrackAudio[];

        const videoTracksOutput: OvenMediaTrackVideo[] = [];
        const audioTracksOutput: OvenMediaTrackAudio[] = [];

        if (item.stream.outputs) {
          item.stream.outputs.forEach((o) => {
            if (o.tracks) {
              o.tracks.forEach((track) => {
                if (track.type === "Video") {
                  videoTracksOutput.push(track);
                } else if (track.type === "Audio") {
                  audioTracksOutput.push(track);
                }
              });
            }
          });
        }

        return (
          <Touchable
            className={styles.tracks}
            onPress={() => {
              showPopup({
                content: (
                  <div style={{ minWidth: 300 }}>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Input:" /* TRANSLATION */}</div>
                    {
                      <div className={styles.tracksContainer}>
                        <div className={styles.tracksSeparator}>
                          <div className={styles.tracksSeparatorLine} />
                          <div className={styles.tracksSeparatorTitle}>{"Video" /* TRANSLATION */}</div>
                          <div className={styles.tracksSeparatorLine} />
                        </div>
                        <TableVideoTrack data={videoTracksInput} />
                        <div className={styles.tracksSeparator} style={{ marginTop: "10px" }}>
                          <div className={styles.tracksSeparatorLine} />
                          <div className={styles.tracksSeparatorTitle}>{"Audio" /* TRANSLATION */}</div>
                          <div className={styles.tracksSeparatorLine} />
                        </div>
                        <TableAudioTrack data={audioTracksInput} />
                      </div>
                    }
                    <br />
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Output:" /* TRANSLATION */}</div>
                    {
                      <div className={styles.tracksContainer}>
                        <div className={styles.tracksSeparator}>
                          <div className={styles.tracksSeparatorLine} />
                          <div className={styles.tracksSeparatorTitle}>{"Video" /* TRANSLATION */}</div>
                          <div className={styles.tracksSeparatorLine} />
                        </div>
                        <TableVideoTrack data={videoTracksOutput} />
                        <div className={styles.tracksSeparator} style={{ marginTop: "10px" }}>
                          <div className={styles.tracksSeparatorLine} />
                          <div className={styles.tracksSeparatorTitle}>{"Audio" /* TRANSLATION */}</div>
                          <div className={styles.tracksSeparatorLine} />
                        </div>
                        <TableAudioTrack data={audioTracksOutput} />
                      </div>
                    }
                    <br />
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconTracks width={30} height={30} />
          </Touchable>
        );
      }
      if (columnKey === "data") {
        if (!item.stream?.live?.data) return "-";
        return (
          <Touchable
            className={styles.actionPlay}
            onPress={() => {
              let data = item.stream?.live?.data;
              if (data) {
                try {
                  const json = JSON.parse(data);
                  data = JSON.stringify(json, null, 4);
                } catch (err) {}
              }

              showPopup({
                content: (
                  <div>
                    <div className={styles.popupTitle}>Data:</div>
                    {data}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconData width={20} height={20} />
          </Touchable>
        );
      }
      if (columnKey === "actions") {
        const context = item.context;
        const profiles: OvenMediaAppOutputProfile[] = context?.vhosts[item.stream.vhost]?.apps?.[item.stream.app].outputProfiles ?? [];
        const playlists: OvenMediaAppOutputProfilePlaylist[] = [];
        profiles.forEach((profil) => {
          if (profil?.playlists) {
            playlists.push(...profil.playlists);
          }
        });
        const popuRef: React.MutableRefObject<PopupRef | null> = { current: null };
        const play = (playlist?: OvenMediaAppOutputProfilePlaylist | string) => {
          let src;
          if (typeof playlist !== "string") {
            const baseUrl = getOvenMediaWebsocketUrl(context);
            if (!baseUrl) return null;
            src = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}/${playlist?.name ?? ""}`);
          } else {
            src = playlist;
          }
          showPopup({
            content: (
              <PlayerOvenMedia
                enableVideoControls
                src={src}
                style={{
                  minWidth: "200px",
                  maxWidth: "80vw",
                }}
              />
            ),
            buttons: [{ type: PopupButtonType.OK, element: "OK" }],
          });
        };

        const renderThumbnailButton = () => {
          let thumbnail = false;
          if (item.app.outputProfiles) {
            for (let p of item.app.outputProfiles) {
              if (p.encodes.images?.length) {
                thumbnail = true;
                break;
              }
            }
          }
          if (!thumbnail) return null;
          return (
            <Touchable
              className={styles.actionPlay}
              onPress={() => {
                window.open(addToPathNameUrl(getBaseUrl(), `/thumbnail/${item.stream.app}/${item.stream.stream}`), "_blank");
              }}
            >
              <IconThumbnail width={20} height={20} />
            </Touchable>
          );
        };
        return (
          <div className={styles.actions}>
            {renderThumbnailButton()}
            <Touchable
              className={styles.actionPlay}
              onPress={() => {
                showPopup(
                  {
                    content: (
                      <div>
                        <div className={styles.popupTitle}>Liste des urls pour les players.</div>
                        {item.app.publishers?.includes(OvenMediaAppPublisher.WEBRTC) ? (
                          <div style={{ marginTop: "10px" }}>
                            <div className={styles.popupSubtitle}>WebRTC / Websocket :</div>
                            {(() => {
                              const baseUrl = getOvenMediaWebsocketUrl(context);
                              if (!baseUrl) return null;
                              const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}`);
                              return (
                                <Touchable
                                  style={{ color: "blue", textDecoration: "underline" }}
                                  onPress={() => {
                                    window.open(url, "_blank");
                                  }}
                                >
                                  {url}
                                </Touchable>
                              );
                            })()}
                            {playlists.map((p) => {
                              const baseUrl = getOvenMediaWebsocketUrl(context);
                              if (!baseUrl) return null;
                              const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}/${p ? p.name : ""}`);
                              return (
                                <Touchable
                                  key={p.name}
                                  style={{ color: "blue", textDecoration: "underline" }}
                                  onPress={() => {
                                    window.open(url, "_blank");
                                  }}
                                >
                                  {url}
                                </Touchable>
                              );
                            })}
                          </div>
                        ) : null}

                        {item.app.publishers?.includes(OvenMediaAppPublisher.LLHLS) ? (
                          <div style={{ marginTop: "10px" }}>
                            <div className={styles.popupSubtitle}>LLHLS :</div>
                            {playlists.map((p) => {
                              const baseUrl = getOvenMediaLLHlsUrl(context);
                              if (!baseUrl) return null;
                              const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}/${p ? p.name : ""}.m3u8`);
                              return (
                                <Touchable
                                  key={p.name}
                                  style={{ color: "blue", textDecoration: "underline" }}
                                  onPress={() => {
                                    window.open(url, "_blank");
                                  }}
                                >
                                  {url}
                                </Touchable>
                              );
                            })}
                          </div>
                        ) : null}

                        {item.app.publishers?.includes(OvenMediaAppPublisher.HLS) ? (
                          <div style={{ marginTop: "10px" }}>
                            <div className={styles.popupSubtitle}>HLS :</div>
                            {playlists.map((p) => {
                              const baseUrl = getOvenMediaHlsUrl(context);
                              if (!baseUrl) return null;
                              const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}/ts:${p ? p.name : ""}.m3u8`);
                              return (
                                <Touchable
                                  key={p.name}
                                  style={{ color: "blue", textDecoration: "underline" }}
                                  onPress={() => {
                                    window.open(url, "_blank");
                                  }}
                                >
                                  {url}
                                </Touchable>
                              );
                            })}
                          </div>
                        ) : null}

                        {item.app.publishers?.includes(OvenMediaAppPublisher.OVT) ? (
                          <div style={{ marginTop: "10px" }}>
                            <div className={styles.popupSubtitle}>OVT :</div>
                            {(() => {
                              const baseUrl = getOvenMediaOvtUrl(context);
                              if (!baseUrl) return null;
                              const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}`);
                              return (
                                <Touchable
                                  style={{ color: "blue", textDecoration: "underline" }}
                                  onPress={() => {
                                    window.open(url, "_blank");
                                  }}
                                >
                                  {url}
                                </Touchable>
                              );
                            })()}
                          </div>
                        ) : null}
                      </div>
                    ),
                    buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                  },
                  popuRef
                );
              }}
            >
              <IconLink width={20} height={20} />
            </Touchable>
            <Touchable
              className={styles.actionPlay}
              onPress={() => {
                showPopup(
                  {
                    content: (
                      <div style={{ minWidth: 300 }}>
                        <div className={styles.popupTitle}>Playlists :</div>
                        {item.app.publishers?.includes(OvenMediaAppPublisher.WEBRTC) ? (
                          <div>
                            <div className={styles.popupSubtitle}>WebRTC :</div>
                            <div className={styles.actionPlaylistsPopupContainer}>
                              <Touchable
                                className={styles.actionPlaylistPopup}
                                onPress={() => {
                                  popuRef?.current?.close();
                                  play();
                                }}
                              >
                                <IconPlay width={20} height={20} />
                                <div>Défaut</div>
                              </Touchable>
                              {playlists.map((playlist, index) => (
                                <Touchable
                                  key={playlist.name}
                                  className={styles.actionPlaylistPopup}
                                  onPress={() => {
                                    popuRef?.current?.close();
                                    play(playlist);
                                  }}
                                >
                                  <IconPlay width={20} height={20} />
                                  <div>{playlist.name}</div>
                                </Touchable>
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {item.app.publishers?.includes(OvenMediaAppPublisher.LLHLS) ? (
                          <div>
                            <div className={styles.popupSubtitle}>LLHLS :</div>
                            <div className={styles.actionPlaylistsPopupContainer}>
                              {playlists.map((playlist, index) => {
                                const baseUrl = getOvenMediaLLHlsUrl(context);
                                if (!baseUrl) return null;
                                return (
                                  <Touchable
                                    key={playlist.name}
                                    className={styles.actionPlaylistPopup}
                                    onPress={() => {
                                      const url = addToPathNameUrl(baseUrl, `/${item.stream.app}/${item.stream.stream}/${playlist ? playlist.name : ""}.m3u8`);
                                      popuRef?.current?.close();
                                      play(url);
                                    }}
                                  >
                                    <IconPlay width={20} height={20} />
                                    <div>{playlist.name}</div>
                                  </Touchable>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                        {item.app.publishers?.includes(OvenMediaAppPublisher.HLS) ? (
                          <div>
                            <div className={styles.popupSubtitle}>HLS :</div>
                            <div className={styles.actionPlaylistsPopupContainer}>
                              {playlists.map((playlist, index) => {
                                const baseUrl = getOvenMediaHlsUrl(context);
                                if (!baseUrl) return null;
                                return (
                                  <Touchable
                                    key={playlist.name}
                                    className={styles.actionPlaylistPopup}
                                    onPress={() => {
                                      const url = addToPathNameUrl(
                                        baseUrl,
                                        `/${item.stream.app}/${item.stream.stream}/ts:${playlist ? playlist.name : ""}.m3u8`
                                      );
                                      popuRef?.current?.close();
                                      play(url);
                                    }}
                                  >
                                    <IconPlay width={20} height={20} />
                                    <div>{playlist.name}</div>
                                  </Touchable>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ),
                    buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                  },
                  popuRef
                );
              }}
            >
              <IconPlay width={20} height={20} />
            </Touchable>
            <Touchable
              className={styles.actionPlay}
              onPress={() => {
                const confQueries = getConfQueries();
                const url = `/player/${item.stream.app}/${item.stream.stream}`;
                let queries = "";
                Object.keys(confQueries).forEach((q) => {
                  const v = confQueries[q];
                  if (v) {
                    queries += queries.length ? "&" : "?";
                    queries += `${q}=${v}`;
                  }
                });
                window.open(url + queries, "_blank");
              }}
            >
              <IconPlayer width={20} height={20} />
            </Touchable>
            <Touchable
              onPress={() => {
                const live = item.stream.live;
                showPopup({
                  title: "Arrêter le stream",
                  iconTitle: PopupIconType.WARNING,
                  content: `Êtes-vous sûr de vouloir arrêter ce stream ?`,
                  buttons: [
                    { type: PopupButtonType.CANCEL, element: "Non" },
                    {
                      type: PopupButtonType.VALIDATE,
                      element: "Oui",
                      onClick: async () => {
                        if (live) {
                          const result = await socketDispatch(socketStopStream({ serverId: live._server, data: { liveSession: live.id } }));
                          if (!result.error) {
                            toastSuccess("La session a bien été stoppée.");
                          } else {
                            toastError(`Impossible d'arrêter la session ${live.id}`);
                          }
                        } else {
                          const result = await socketDispatch(
                            socketStopStream({ serverId: item.context?.server?._id, data: { app: item.app?.app, stream: item.stream?.stream } })
                          );
                          if (!result.error) {
                            toastSuccess("La session a bien été stoppée.");
                          } else {
                            toastError(`Impossible d'arrêter la session /${item.app}/${item.stream}`);
                          }
                        }

                        return true;
                      },
                    },
                  ],
                  enableBackdropDismiss: true,
                  enableCloseButton: true,
                });
              }}
            >
              <IconClose stroke={Colors.getTorchRed()} strokeWidth={2} width={20} height={20} />
            </Touchable>
          </div>
        );
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableApplicationData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableApplicationData, currData: TableApplicationData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const onRenderRow = (
      row: { defaultRow: React.ReactNode; elements: JSX.Element[]; classes: string[]; style: React.CSSProperties },
      item: TableApplicationData,
      data: TableApplicationData[],
      index: number
    ) => {
      if (item.ovts?.length) {
        const active = itemsActive.find((i) => i.server === item.context.server._id && i.app === item.app.app && i.stream === item.stream.stream);
        return (
          <div style={active ? { borderRadius: "10px", border: "1px solid black", overflow: "hidden" } : {}}>
            <Touchable
              className={row.classes.join(" ")}
              style={{ ...row.style, backgroundColor: "#ecf0ff" }}
              onPress={() => {
                const newItems = itemsActive.filter((i) => i.server !== item.context.server._id || i.app !== item.app.app || i.stream !== item.stream.stream);
                if (!active) newItems.push({ server: item.context.server._id, app: item.app.app, stream: item.stream.stream });
                setItemActive(newItems);
              }}
            >
              {row.elements}
            </Touchable>
            {active ? (
              <TableApplication
                onConf={(conf) => {
                  if (conf.content) conf.content.className = `${styles.tableContentActive}`;
                  return conf;
                }}
                showApp={showApp}
                showServer={showServer}
                hideHeader
                data={[{ ...item, ovts: undefined }, ...item.ovts]}
              />
            ) : null}
          </div>
        );
      }
      return row.defaultRow;
    };

    const transformValue = (columnKey: string, item: TableApplicationData, initialValue: any, data: TableApplicationData[], index: number) => {
      if (columnKey === "startedAt") {
        const date = item.stream?.live?.startedAt ?? item.stream.input?.createdTime;
        if (!date) return undefined;
        const startedAt = new Date(date);
        return `${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`;
      }
      return initialValue;
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.context.server._id}-${item.stream.stream}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        onRenderRow={onRenderRow}
        transformValue={transformValue}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

export default TableApplication;
