import { Touchable, useStateWithRef, Video, VideoNotAllowedStrategy } from "@kalyzee/kast-app-web-components";
import { OvenMediaReceiverWebRTCSession } from "@kalyzee/kast-webrtc-client-module";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactComponent as IconPlay } from "../../assets/icons/play.svg";
import PageContainer from "../../components/page/PageContainer";
import RtcAudioStats, { RtcAudioStatsRef } from "../../components/rtc/rtc-audio-stats";
import RtcVideoStats, { RtcVideoStatsRef } from "../../components/rtc/rtc-video-stats";
import { VHOST } from "../../constants/ovenmedia";
import { getBaseUrl, getOvenMediaHlsUrl, getOvenMediaLLHlsUrl, getOvenMediaWebsocketUrl } from "../../helpers/request";
import { addToPathNameUrl } from "../../helpers/utils";
import {
  OvenMediaAppContext,
  OvenMediaAppOutputProfile,
  OvenMediaAppOutputProfilePlaylist,
  OvenMediaAppPublisher,
  OvenMediaContext,
} from "../../interfaces/context";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import styles from "./player.module.css";
import { Stats } from "./stats";

const generateSrc = (type: "webrtc" | "llhls" | "hls", context: OvenMediaContext, app?: string, stream?: string, playlist?: string): string | undefined => {
  if (type === "webrtc") {
    const url = getOvenMediaWebsocketUrl(context);
    if (!url) return undefined;
    return addToPathNameUrl(url, `/${app}/${stream}/${playlist ? playlist : ""}`);
  }
  if (type === "llhls") {
    const url = getOvenMediaLLHlsUrl(context);
    if (!url) return undefined;
    return addToPathNameUrl(url, `/${app}/${stream}/${playlist ? playlist : ""}.m3u8`);
  }
  if (type === "hls") {
    const url = getOvenMediaHlsUrl(context);
    if (!url) return undefined;
    return addToPathNameUrl(url, `/${app}/${stream}/ts:${playlist ? playlist : ""}.m3u8`);
  }
  return undefined;
};

const PlayerPage = () => {
  const { app, stream } = useParams();
  const [searchParams] = useSearchParams();
  const contexts = useOvenMediaContexts();
  const [type, setType] = useState<"webrtc" | "llhls" | "hls">("webrtc");
  const [playlist, setPlaylist] = useState<string | undefined>("");
  const [media, setMedia] = useState<MediaStream | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);

  const webrtcSession = useRef<OvenMediaReceiverWebRTCSession | undefined>(undefined);
  const videoStatsRef = useRef<RtcVideoStatsRef>();
  const audioStatsRef = useRef<RtcAudioStatsRef>();
  const [currContext, setCurrContext, currContextRef] = useStateWithRef<OvenMediaContext | undefined>();
  const [serverId, setServerId] = useState(searchParams.get("serverId") ?? undefined);
  const multiServer = useMultiServer();

  const fullscreen = (searchParams.get("fullscreen") ?? "") === "true";          

  useEffect(() => {
    const updateCurrContext = (ctx?: OvenMediaContext) => {
      if (currContextRef.current?.server?._id !== ctx?.server?._id) setCurrContext(ctx);
    }
    if (serverId) {
      const context = contexts.find((c) => c.server._id === serverId);
      if (context) updateCurrContext(context);
      else updateCurrContext(contexts[0]);
    } else if (multiServer) {
      updateCurrContext(contexts[0]);
    }
    if (!multiServer) updateCurrContext(contexts[0]);
  }, [contexts, serverId]);

  useEffect(() => {
    if (type !== "webrtc") return;
    if (!currContext) return;
    const src = generateSrc("webrtc", currContext, app, stream, playlist);
    if (!src || src === webrtcSession.current?.currentSrc) return;

    const appContext = app ? currContext.vhosts[VHOST].apps[app] : undefined;
    if (appContext) {
      if (appContext.outputProfiles) {
        for (let p of appContext.outputProfiles) {
          if (p.encodes.images?.length) {
            setThumbnail(addToPathNameUrl(getBaseUrl(), `/thumbnail/${app}/${stream}`))
          }
        }
      }
    }
    
    const session = new OvenMediaReceiverWebRTCSession();
    session.start(src);
    session.addEventListener("stream", (stream) => {
      setMedia(stream);
    });
    webrtcSession.current = session;
    return () => {
      session.destroy();
    };
  }, [currContext, app, stream, playlist, type]);

  const renderContent = (context: OvenMediaContext) => {
    const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
    const profiles: OvenMediaAppOutputProfile[] = appContext?.outputProfiles ?? [];
    const playlists: OvenMediaAppOutputProfilePlaylist[] = [];

    if (profiles) {
      profiles.forEach((profile) => {
        if (profile.playlists) {
          playlists.push(...profile.playlists);
        }
      });
    }

    const llhls = generateSrc("llhls", context, app, stream, playlist);
    const hls = generateSrc("hls", context, app, stream, playlist);
    return (
      <>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Application : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{app}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Stream : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{stream}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Type : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{type}</div>
        </div>
        <div className={styles.descriptionRow}>
          <div className={styles.descriptionTitle}>{"Playlist : " /* TRANSLATION */}</div>
          <div className={styles.descriptionValue}>{playlist}</div>
        </div>
        <div className={styles.sectionTitle}>{"Playlists:" /* TRANSLATION */}</div>
        <div className={styles.playlistsContainer}>
          <div className={styles.playlistsTitle}>Webrtc: </div>
          <div className={styles.playlists}>
            <Touchable
              className={styles.playlistButton + (type === "webrtc" && playlist === undefined ? ` ${styles.playlistButtonSelected}` : "")}
              onPress={() => {
                setType("webrtc");
                setPlaylist(undefined);
              }}
            >
              <IconPlay width={20} height={20} />
              <div>Défaut</div>
            </Touchable>
            {playlists.map((p, index) => (
              <Touchable
                key={p.name}
                className={styles.playlistButton + (type === "webrtc" && playlist === p.name ? ` ${styles.playlistButtonSelected}` : "")}
                onPress={() => {
                  setType("webrtc");
                  setPlaylist(p.name);
                }}
              >
                <IconPlay width={20} height={20} />
                <div>{p.name}</div>
              </Touchable>
            ))}
          </div>
        </div>
        {appContext?.publishers?.includes(OvenMediaAppPublisher.LLHLS) ? (
          <div className={styles.playlistsContainer} style={{ marginTop: "10px" }}>
            <div className={styles.playlistsTitle}>LLHls: </div>
            <div className={styles.playlists}>
              <Touchable
                className={styles.playlistButton + (type === "llhls" && playlist === undefined ? ` ${styles.playlistButtonSelected}` : "")}
                onPress={() => {
                  setType("llhls");
                  setPlaylist(undefined);
                }}
              >
                <IconPlay width={20} height={20} />
                <div>Défaut</div>
              </Touchable>
              {playlists.map((p, index) => (
                <Touchable
                  key={p.name}
                  className={styles.playlistButton + (type === "llhls" && playlist === p.name ? ` ${styles.playlistButtonSelected}` : "")}
                  onPress={() => {
                    setType("llhls");
                    setPlaylist(p.name);
                  }}
                >
                  <IconPlay width={20} height={20} />
                  <div>{p.name}</div>
                </Touchable>
              ))}
            </div>
          </div>
        ) : null}

        {appContext?.publishers?.includes(OvenMediaAppPublisher.HLS) ? (
          <div className={styles.playlistsContainer} style={{ marginTop: "10px" }}>
            <div className={styles.playlistsTitle}>HLS: </div>
            <div className={styles.playlists}>
              <Touchable
                className={styles.playlistButton + (type === "hls" && playlist === undefined ? ` ${styles.playlistButtonSelected}` : "")}
                onPress={() => {
                  setType("hls");
                  setPlaylist(undefined);
                }}
              >
                <IconPlay width={20} height={20} />
                <div>Défaut</div>
              </Touchable>
              {playlists.map((p, index) => (
                <Touchable
                  key={p.name}
                  className={styles.playlistButton + (type === "hls" && playlist === p.name ? ` ${styles.playlistButtonSelected}` : "")}
                  onPress={() => {
                    setType("hls");
                    setPlaylist(p.name);
                  }}
                >
                  <IconPlay width={20} height={20} />
                  <div>{p.name}</div>
                </Touchable>
              ))}
            </div>
          </div>
        ) : null}
        <div className={styles.sectionTitle}>{"Player:" /* TRANSLATION */}</div>
        <div className={styles.playerContainer}>
          {type === "webrtc" ? (
            <Video notAllowedStrategy={VideoNotAllowedStrategy.RETRY_INDEFINITELY} poster={thumbnail} controls autoPlay src={media} className={`${styles.player}${fullscreen ? ` ${styles.fullscreenPlayer}` : ""}`} />
          ) : type === "llhls" ? (
            llhls ? (
              <Video notAllowedStrategy={VideoNotAllowedStrategy.RETRY_INDEFINITELY} poster={thumbnail} controls autoPlay src={{ type: "hls", src: llhls }} className={`${styles.player}${fullscreen ? ` ${styles.fullscreenPlayer}` : ""}`} />
            ) : null
          ) : type === "hls" ? (
            hls ? (
              <Video notAllowedStrategy={VideoNotAllowedStrategy.RETRY_INDEFINITELY} poster={thumbnail} controls autoPlay src={{ type: "hls", src: hls }} className={`${styles.player}${fullscreen ? ` ${styles.fullscreenPlayer}` : ""}`} />
            ) : null
          ) : null}
        </div>
        {type === "webrtc" ? (
          <div className={styles.sectionTitle}>
            {"Stats:" /* TRANSLATION */}
            <div className={styles.statsContainer}>
              <Stats session={webrtcSession.current} />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  if (!contexts.length) return null;
  return (
    <PageContainer title={"Player" /* TRANSLATION */} subtitle={app} loading={false}>
      <div style={{ width: "100%" }}>
        {multiServer ? (
          <Tabs
            onSelect={(index) => {
              const context = index < contexts.length ? contexts[index] : undefined;
              setServerId(context?.server._id);
            }}
          >
            <TabList>
              {contexts.map((c) => (
                <Tab key={`player_tab_${c.server._id}`}>{`Server ${c.server._id}`}</Tab>
              ))}
            </TabList>
            {contexts.map((c) => (
              <TabPanel key={`player_content_${c.server._id}`}>{renderContent(c)}</TabPanel>
            ))}
          </Tabs>
        ) : (
          renderContent(contexts[0])
        )}
      </div>
    </PageContainer>
  );
};

export default PlayerPage;
