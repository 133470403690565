import { useStateWithRef } from "@kalyzee/kast-app-web-components";
import { useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PageContainer from "../../components/page/PageContainer";
import { useSocketAppDispatch } from "../../hooks/app";
import { OvenMediaContext } from "../../interfaces/context";
import { useOvenMediaContexts } from "../../store/context/hooks";
import { useMultiServer } from "../../store/session/hooks";
import styles from "./servers.module.css";

const ServersPage = () => {
  const multiServer = useMultiServer();
  const contexts = useOvenMediaContexts();

  const renderContent = (context?: OvenMediaContext) => {
    const server = context?.server;
    if (!server) return null;
    return (
      <>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Server:</div>
          <div className={styles.rowValue}>{server._id}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>OvenMedia version:</div>
          <div className={styles.rowValue}>{server.ovenMediaVersion}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>OvenMedia docker image:</div>
          <div className={styles.rowValue}>{server.ovenMediaDockerImage}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Api:</div>
          <div className={styles.rowValue}>{server.apiUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Api version:</div>
          <div className={styles.rowValue}>{server.apiVersion}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Api docker image:</div>
          <div className={styles.rowValue}>{server.apiDockerImage}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Webrtc/Hls/LLHS - pull</div>
          <div className={styles.rowValue}>{server.pullWebMediaUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Webrtc/Hls/LLHS - push</div>
          <div className={styles.rowValue}>{server.pushWebMediaUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Webrtc/Hls/LLHS - pull (tls):</div>
          <div className={styles.rowValue}>{server.pullWebMediaTlsUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Webrtc/Hls/LLHS - push (tls):</div>
          <div className={styles.rowValue}>{server.pushWebMediaTlsUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Thumbnail :</div>
          <div className={styles.rowValue}>{server.thumbnailUrl}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Load balancer - pull :</div>
          <div className={styles.rowValue}>{`Activé: ${(server.loadBalancer?.pulling?.enabled ?? false) ? '✅' : '❌'}`}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Load balancer - push :</div>
          <div className={styles.rowValue}>{`Activé: ${(server.loadBalancer?.pushing?.enabled ?? false) ? '✅' : '❌'}`}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Load balancing - pull :</div>
          <div className={styles.rowValue}>{`Activé: ${(server.loadBalancing?.pulling?.enabled ?? false) ? '✅' : '❌'}  --  Weight: ${server.loadBalancing?.pulling?.weight ?? 1}`}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowTitle}>Load balancing - push :</div>
          <div className={styles.rowValue}>{`Activé: ${(server.loadBalancing?.pushing?.enabled ?? false) ? '✅' : '❌'}  --  Weight: ${server.loadBalancing?.pushing?.weight ?? 1}`}</div>
        </div>
      </>
    );
  };

  return (
    <PageContainer title={"Logs" /* TRANSLATION */} subtitle={""} loading={false}>
      {multiServer ? (
        <Tabs
          className={[styles.tabs].join(" ")}
        >
          <TabList>
            {contexts
              .sort((a, b) => a.server._id.localeCompare(b.server._id))
              .map((c) => (
                <Tab key={`logs_tab_${c.server._id}`}>{`Server ${c.server._id}`}</Tab>
              ))}
          </TabList>
          {contexts.map((c) => (
            <TabPanel className={[styles.tabsContent, "react-tabs-content"].join(" ")} key={`logs_content_${c.server._id}`}>
              {renderContent(c)}
            </TabPanel>
          ))}
        </Tabs>
      ) : (
        renderContent(contexts[0])
      )}
    </PageContainer>
  );
};

export default ServersPage;
