import { RTCStreamStatsResult, WebRTCSession } from "@kalyzee/kast-webrtc-client-module";
import React, { ForwardedRef, useEffect, useState } from "react";

const RTC_STATS_INTERVAL = 1000; // < 0 to disable it

export interface StatsProps {
  target?: "video" | "audio" | "audiovideo";
  session?: WebRTCSession<any> | null;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export interface StatsRef {}

export const Stats = React.forwardRef(({ target = "audiovideo", session, children, style }: StatsProps, forwardRef: ForwardedRef<StatsRef | undefined>) => {
  const [stats, setStats] = useState<{ [id: string]: RTCStreamStatsResult }>();

  useEffect(() => {
    if (!session) {
      setStats(undefined);
      return;
    }
    const statsListener = session.addEventListener("stats", (_, mode, stream, stats) => {
      console.log("STATS - ", session.getId(), " - ", stats.mode, " : ", stats);
      const mediaId = stats.media?.id;
      if (stats.media?.id && (stats.audio || stats.video)) {
        setStats((v) => {
          let value = { ...(v ?? {}) };
          value[mediaId] = stats;
          return value;
        });
      }
    });
    const closeListener = session.addEventListener("close", () => {
      setStats(undefined);
    });

    const rtcSessionListener = session.addEventListener("initialized", () => {
      if (RTC_STATS_INTERVAL >= 0) session.startStatsGeneration(RTC_STATS_INTERVAL);
    });

    if (session.rtcPeerConnection) {
      if (RTC_STATS_INTERVAL >= 0) session.startStatsGeneration(RTC_STATS_INTERVAL);
    }
    return () => {
      statsListener.flush();
      closeListener.flush();
      rtcSessionListener.flush();
    };
  }, [session]);

  if (!stats) return null;
  const renderRow = (title: string, value: any, type: "any" | "bitrate" | "percentage" | "time" = "any") => {
    let str = `${value}`;
    const getNumber = (value: any, defaultValue = 0) => {
      if (value === undefined) return defaultValue;
      const num = Number(value);
      return isNaN(num) ? defaultValue : num;
    };
    if (type === "bitrate") {
      let number = getNumber(value, 0);
      if (number > 1024 * 1024) {
        str = `${(number / (1024 * 1024)).toFixed(2)}Mbits`;
      } else {
        str = `${(number / 1024).toFixed(2)}kbits`;
      }
    } else if (type === "percentage") {
      let number = getNumber(value, 0);
      str = `${(number * 100).toFixed(2)}%`;
    } else if (type === "time") {
      let number = getNumber(value, 0);
      str = `${number.toFixed(1)}s`;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          fontSize: "10px",
        }}
      >
        <div>{`${title}: `}</div>
        <div>{str}</div>
      </div>
    );
  };

  const renderInputStats = (s: RTCStreamStatsResult) => {
    if (s.mode !== "input") return null;
    return (
      <>
        <div
          style={{
            width: "100%",
            textAlign: "start",
          }}
        >
          {target === "video" || target === "audiovideo" ? (
            <>
              Video:
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "5px" }}>
                  {renderRow("bitrate", s.video?.stats?.bitrate, "bitrate")}
                  {renderRow("averageBitrate", s.video?.stats?.averageBitrate, "bitrate")}
                  {renderRow("availableIncomingBitrate", s.video?.stats?.availableIncomingBitrate, "bitrate")}
                  {renderRow("timestamp", s.video?.stats?.timestamp)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("flux entrant", `${s.video?.stats?.frameHeight}*${s.video?.stats?.frameWidth} / ${s.video?.stats?.framePerSecond}fps`)}
                  {renderRow("framesDecoded", s.video?.stats?.framesDecoded)}
                  {renderRow("keyFramesDecoded", s.video?.stats?.keyFramesDecoded)}
                  {renderRow("framesDropped", s.video?.stats?.framesDropped)}
                  {renderRow("freezeCount", s.video?.stats?.freezeCount)}
                  {renderRow("mimeType", s.video?.stats?.mimeType)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("fir", s.video?.stats?.firCount)}
                  {renderRow("pli", s.video?.stats?.pliCount)}
                  {renderRow("nack", s.video?.stats?.nackCount)}
                  {renderRow("qpSum", s.video?.stats?.qpSum)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("packetsLost", s.video?.stats?.packetsLost)}
                  {renderRow("percentagePacketsLost", s.video?.stats?.percentagePacketsLost, "percentage")}
                  {renderRow("snap - packetsLost", s.video?.stats?.packetsLostSnap)}
                  {renderRow("snap - percentagePacketsLost", s.video?.stats?.percentagePacketsLostSnap, "percentage")}
                  {renderRow("pauseCount", s.video?.stats?.pauseCount)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("jitter", s.video?.stats?.jitter?.toFixed(5))}
                  {renderRow("averageJitter", s.video?.stats?.averageJitter?.toFixed(5))}
                  {renderRow("jitterBufferDelay", s.video?.stats?.jitterBufferDelay, "time")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("totalDecodeTime", s.video?.stats?.totalDecodeTime, "time")}
                  {renderRow("totalFreezesDuration", s.video?.stats?.totalFreezesDuration, "time")}
                  {renderRow("totalInterFrameDelay", s.video?.stats?.totalInterFrameDelay, "time")}
                  {renderRow("totalAssemblyTime", s.video?.stats?.totalAssemblyTime, "time")}
                  {renderRow("totalPausesDuration", s.video?.stats?.totalPausesDuration, "time")}
                  {renderRow("totalProcessingDelay", s.video?.stats?.totalProcessingDelay, "time")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("local candidate - type", s.video?.stats?.localCandidate?.type)}
                  {renderRow("local candidate - ip", s.video?.stats?.localCandidate?.ip)}
                  {renderRow("local candidate - protocol", s.video?.stats?.localCandidate?.protocol)}
                  {renderRow("local candidate - relayProtocol", s.video?.stats?.localCandidate?.relayProtocol)}
                  {renderRow("local candidate - url", s.video?.stats?.localCandidate?.url)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("remote candidate - type", s.video?.stats?.remoteCandidate?.type)}
                  {renderRow("remote candidate - ip", s.video?.stats?.remoteCandidate?.ip)}
                  {renderRow("remote candidate - protocol", s.video?.stats?.remoteCandidate?.protocol)}
                </div>
              </div>
            </>
          ) : null}
          {target === "audio" || target === "audiovideo" ? (
            <>
              Audio:
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "5px" }}>
                  {renderRow("bitrate", s.audio?.stats?.bitrate, "bitrate")}
                  {renderRow("averageBitrate", s.audio?.stats?.averageBitrate, "bitrate")}
                  {renderRow("availableIncomingBitrate", s.audio?.stats?.availableIncomingBitrate, "bitrate")}
                  {renderRow("timestamp", s.audio?.stats?.timestamp)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("audioLevel", s.audio?.stats?.audioLevel)}
                  {renderRow("concealedSamples", s.audio?.stats?.concealedSamples)}
                  {renderRow("concealmentEvents", s.audio?.stats?.concealmentEvents)}
                  {renderRow("fecPacketsDiscarded", s.audio?.stats?.fecPacketsDiscarded)}
                  {renderRow("fecPacketsReceived", s.audio?.stats?.fecPacketsReceived)}
                  {renderRow("mimeType", s.audio?.stats?.mimeType)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("estimatedPlayoutTimestamp", s.audio?.stats?.estimatedPlayoutTimestamp)}
                  {renderRow("insertedSamplesForDeceleration", s.audio?.stats?.insertedSamplesForDeceleration)}
                  {renderRow("removedSamplesForAcceleration", s.audio?.stats?.removedSamplesForAcceleration)}
                  {renderRow("silentConcealedSamples", s.audio?.stats?.silentConcealedSamples)}
                  {renderRow("packetsDiscarded", s.audio?.stats?.packetsDiscarded)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("packetsLost", s.audio?.stats?.packetsLost)}
                  {renderRow("percentagePacketsLost", s.audio?.stats?.percentagePacketsLost, "percentage")}
                  {renderRow("snap - packetsLost", s.audio?.stats?.packetsLostSnap)}
                  {renderRow("snap - percentagePacketsLost", s.audio?.stats?.percentagePacketsLostSnap, "percentage")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("jitter", s.audio?.stats?.jitter?.toFixed(5))}
                  {renderRow("averageJitter", s.audio?.stats?.averageJitter?.toFixed(5))}
                  {renderRow("jitterBufferDelay", s.audio?.stats?.jitterBufferDelay, "time")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("totalSamplesDuration", s.audio?.stats?.totalSamplesDuration, "time")}
                  {renderRow("totalProcessingDelay", s.audio?.stats?.totalProcessingDelay, "time")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("local candidate - type", s.audio?.stats?.localCandidate?.type)}
                  {renderRow("local candidate - ip", s.audio?.stats?.localCandidate?.ip)}
                  {renderRow("local candidate - protocol", s.audio?.stats?.localCandidate?.protocol)}
                  {renderRow("local candidate - relayProtocol", s.audio?.stats?.localCandidate?.relayProtocol)}
                  {renderRow("local candidate - url", s.audio?.stats?.localCandidate?.url)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("remote candidate - type", s.audio?.stats?.remoteCandidate?.type)}
                  {renderRow("remote candidate - ip", s.audio?.stats?.remoteCandidate?.ip)}
                  {renderRow("remote candidate - protocol", s.audio?.stats?.remoteCandidate?.protocol)}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };

  const renderOutputStats = (s: RTCStreamStatsResult) => {
    if (s.mode !== "output") return null;
    return (
      <>
        <div
          style={{
            width: "100%",
            textAlign: "start",
          }}
        >
          {target === "video" || target === "audiovideo" ? (
            <>
              Video:
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "5px" }}>
                  {renderRow("bitrate", s.video?.stats?.bitrate, "bitrate")}
                  {renderRow("averageBitrate", s.video?.stats?.averageBitrate, "bitrate")}
                  {renderRow("target", s.video?.stats?.targetBitrate, "bitrate")}
                  {renderRow("availableOutgoingBitrate", s.video?.stats?.availableOutgoingBitrate, "bitrate")}
                  {renderRow("timestamp", s.video?.stats?.timestamp)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("flux sortant", `${s.video?.stats?.frameHeight}*${s.video?.stats?.frameWidth} / ${s.video?.stats?.framePerSecond}fps`)}
                  {renderRow(
                    "media source",
                    `${s.video?.stats?.mediaSouce?.height}*${s.video?.stats?.mediaSouce?.width} / ${s.video?.stats?.mediaSouce?.framesPerSecond}fps`
                  )}
                  {renderRow("framesEncoded", s.video?.stats?.framesEncoded)}
                  {renderRow("keyFramesEncoded", s.video?.stats?.keyFramesEncoded)}
                  {renderRow("encoder", s.video?.stats?.encoder)}
                  {renderRow("mimeType", s.video?.stats?.mimeType)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("current limitation", s.video?.stats?.qualityLimitationReason)}
                  {renderRow("bandwidth limitation ", s.video?.stats?.qualityLimitationDurations?.bandwidth?.percentage, "percentage")}
                  {renderRow("cpu limitation ", s.video?.stats?.qualityLimitationDurations?.cpu?.percentage, "percentage")}
                  {renderRow("other limitation ", s.video?.stats?.qualityLimitationDurations?.other?.percentage, "percentage")}
                  {renderRow("none limitation ", s.video?.stats?.qualityLimitationDurations?.none?.percentage, "percentage")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("fir", s.video?.stats?.firCount)}
                  {renderRow("pli", s.video?.stats?.pliCount)}
                  {renderRow("nack", s.video?.stats?.nackCount)}
                  {renderRow("qpSum", s.video?.stats?.qpSum)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("packetsLost", s.video?.stats?.packetsLost)}
                  {renderRow("percentagePacketsLost", s.video?.stats?.percentagePacketsLost, "percentage")}
                  {renderRow("snap - packetsLost", s.video?.stats?.packetsLostSnap)}
                  {renderRow("snap - percentagePacketsLost", s.video?.stats?.percentagePacketsLostSnap, "percentage")}
                  {renderRow("retransmittedPacketsSent", s.video?.stats?.retransmittedPacketsSent)}
                </div>

                <div style={{ padding: "5px" }}>
                  {renderRow("jitter", s.video?.stats?.jitter?.toFixed(5))}
                  {renderRow("averageJitter", s.video?.stats?.averageJitter?.toFixed(5))}
                  {renderRow("totalEncodeTime", s.video?.stats?.totalEncodeTime, "time")}
                  {renderRow("totalPacketSendDelay", s.video?.stats?.totalPacketSendDelay, "time")}
                  {renderRow("totalTime", s.video?.stats?.qualityLimitationDurations?.total, "time")}
                </div>

                <div style={{ padding: "5px" }}>
                  {renderRow("local candidate - type", s.video?.stats?.localCandidate?.type)}
                  {renderRow("local candidate - ip", s.video?.stats?.localCandidate?.ip)}
                  {renderRow("local candidate - protocol", s.video?.stats?.localCandidate?.protocol)}
                  {renderRow("local candidate - relayProtocol", s.video?.stats?.localCandidate?.relayProtocol)}
                  {renderRow("local candidate - url", s.video?.stats?.localCandidate?.url)}
                </div>

                <div style={{ padding: "5px" }}>
                  {renderRow("remote candidate - type", s.video?.stats?.remoteCandidate?.type)}
                  {renderRow("remote candidate - ip", s.video?.stats?.remoteCandidate?.ip)}
                  {renderRow("remote candidate - protocol", s.video?.stats?.remoteCandidate?.protocol)}
                </div>
              </div>
            </>
          ) : null}

          {target === "audio" || target === "audiovideo" ? (
            <>
              Audio:
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "5px" }}>
                  {renderRow("bitrate", s.audio?.stats?.bitrate, "bitrate")}
                  {renderRow("averageBitrate", s.audio?.stats?.averageBitrate, "bitrate")}
                  {renderRow("target", s.audio?.stats?.targetBitrate, "bitrate")}
                  {renderRow("availableOutgoingBitrate", s.audio?.stats?.availableOutgoingBitrate, "bitrate")}
                  {renderRow("timestamp", s.audio?.stats?.timestamp)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("media source - audio level", s.audio?.stats?.mediaSouce?.audioLevel?.toFixed(6))}
                  {renderRow("media source - echoReturnLoss", s.audio?.stats?.mediaSouce?.echoReturnLoss)}
                  {renderRow("media source - echoReturnLossEnhancement", s.audio?.stats?.mediaSouce?.echoReturnLossEnhancement?.toFixed(4))}
                  {renderRow("mimeType", s.audio?.stats?.mimeType)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("packetsLost", s.audio?.stats?.packetsLost)}
                  {renderRow("percentagePacketsLost", s.audio?.stats?.percentagePacketsLost, "percentage")}
                  {renderRow("snap - packetsLost", s.audio?.stats?.packetsLostSnap)}
                  {renderRow("snap - percentagePacketsLost", s.audio?.stats?.percentagePacketsLostSnap, "percentage")}
                  {renderRow("retransmittedPacketsSent", s.audio?.stats?.retransmittedPacketsSent)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("jitter", s.audio?.stats?.jitter?.toFixed(5))}
                  {renderRow("averageJitter", s.audio?.stats?.averageJitter?.toFixed(5))}
                  {renderRow("totalPacketSendDelay", s.audio?.stats?.totalPacketSendDelay, "time")}
                  {renderRow("totalTime", s.audio?.stats?.mediaSouce?.totalSamplesDuration, "time")}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("local candidate - type", s.audio?.stats?.localCandidate?.type)}
                  {renderRow("local candidate - ip", s.audio?.stats?.localCandidate?.ip)}
                  {renderRow("local candidate - protocol", s.audio?.stats?.localCandidate?.protocol)}
                  {renderRow("local candidate - relayProtocol", s.audio?.stats?.localCandidate?.relayProtocol)}
                  {renderRow("local candidate - url", s.audio?.stats?.localCandidate?.url)}
                </div>
                <div style={{ padding: "5px" }}>
                  {renderRow("remote candidate - type", s.audio?.stats?.remoteCandidate?.type)}
                  {renderRow("remote candidate - ip", s.audio?.stats?.remoteCandidate?.ip)}
                  {renderRow("remote candidate - protocol", s.audio?.stats?.remoteCandidate?.protocol)}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      {Object.keys(stats).map((id) => {
        return (
          <>
            {renderInputStats(stats[id])}
            {renderOutputStats(stats[id])}
          </>
        );
      })}
    </div>
  );
});
